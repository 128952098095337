import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PwLayoutModule } from 'pw-lib';
import { FooterComponent } from './footer/footer.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { PageWrapperComponent } from './page-wrapper/page-wrapper.component';

@NgModule({
  declarations: [NavMenuComponent, PageWrapperComponent, FooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    TranslateModule,
    PwLayoutModule,
  ],
  exports: [
    NavMenuComponent,
    PageWrapperComponent,
    FooterComponent,
    PwLayoutModule,
  ],
})
export class LayoutModule {}
