import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { PwTuiEditorModule } from 'pw-lib';
import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ExcelInputComponent } from './excel-input/excel-input.component';
import { HtmlEditorDialogComponent } from './html-editor-dialog/html-editor-dialog.component';

const componentList = [
  AlertComponent,
  ConfirmComponent,
  HtmlEditorDialogComponent,
  ExcelInputComponent,
];

@NgModule({
  declarations: componentList,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    ReactiveFormsModule,
    PwTuiEditorModule,
    FlexLayoutModule,
  ],
  exports: [...componentList, MatDialogModule],
})
export class DialogModule {}
