import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { camelCase } from 'lodash-es';
import { Observable, of } from 'rxjs';

/**
 * Table 정의서 String 타입 Pipe. 번역 키 그룹(REPO.STRING) 을 붙여 번역하거나, masking 등 처리 후 반환한다.
 */
@Pipe({
  name: 'string',
})
export class StringPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  /**
   * @param value: string[]
   * @param options: { translate: boolean } = { translate: false } 번역 여부.
   */
  transform(
    value: string,
    options: { translate: boolean } = { translate: false }
  ): Observable<string> {
    // 값이 없으면 - 반환
    if (value === undefined || value === null) {
      // 없으면 반환
      return of('');
    }

    if (options.translate) {
      return this.translateService.stream(`REPO.STRING.${camelCase(value)}`);
    }

    return of(value);
  }
}
