<h1 mat-dialog-title>{{ 'excelUpload' | translate }}</h1>
<mat-dialog-content>
  <input
    #xlsxUpload
    type="file"
    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    (change)="onFileChange()"
  />
</mat-dialog-content>
<mat-dialog-actions fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="stretch end">
  <button mat-button color="accent" (click)="onClickExportForm()">
    {{ 'BTN.exportForm' | translate }}
  </button>
  <div fxFlex fxHide.lt-sm></div>
  <button mat-button mat-dialog-close>
    {{ 'BTN.cancel' | translate }}
  </button>
  <button
    mat-button
    [disabled]="!xlsxUpload.files?.length"
    [mat-dialog-close]="xlsxUpload.files[0]"
  >
    {{ 'BTN.upload' | translate }}
  </button>
</mat-dialog-actions>
